<template>
      <router-view  />
</template>

<script>
// import autofit from 'autofit.js'
export default {  
  mounted() {
  // autofit.init({
  //       // designHeight: 1080,
  //       // designWidth: 1920,
  //       // renderDom:"#app",
  //       // resize: true
  //   })
  },
}
</script>

<style>
/* *{
  padding: 0;
  margin: 0;
} */
button{
  cursor: pointer;
}
</style>
