import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import animated from 'animate.css'
import axios from 'axios';
import * as XLSX from 'xlsx'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import {
	setToken,
	getToken,
	setLoginform,
	removeLoginform,
	setInfo,
	removeToken
} from '@/utils/auth'
import VueClipboard from 'vue-clipboard2'

// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';
import './assets/arcoament.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
	duration: 1000,
	easing: "ease-in-out-back"
});

import { Notification, Message } from '@arco-design/web-vue';
//引入自定义字体
import '@/assets/font/font.css';
import { getInfodata } from './api/apiList'
router.beforeEach((to, from, next) => {
	// 需要暂存的页面路由
	const pathdata = ['/PurchasingMGTCenter', '/AccountManagement', '/OrderCenter']
	if (pathdata.includes(to.path)) {
		// 当前页  
	} else {
		// 非当前页  
		sessionStorage.removeItem(`${from.name}Index`)
	}

	var url = window.location.href;
	// console.log(url)
	var data = []
	var result = [];
	if (url.substring(url.indexOf('?') + 1 == -1) || url.substring(url.indexOf('#') == -1)) {
		var querys1 = url.substring(url.indexOf('?') + 1).split('&')
		var querys = url.substring(url.indexOf('#') + 1).split('&')
		data = [...querys1, ...querys]
	}
	for (var i = 0; i < data.length; i++) {
		const temp = data[i].split('=');
		if (temp.length < 2) {
			result[temp[0]] = '';
		} else {
			result[temp[0]] = temp[1];
		}
	}
	// console.log(result)
	// console.log(result.code)
	// 微信登录
	if (result.code != undefined) {
		if (!getToken()) {
			let params = {
				code: result.code,
				state: result.state,
			}
			axios({
				url: `${process.env.VUE_APP_BASE_API}/atp/wx/callback?code=${params.code}&state=${params.state}`, // 设置 URL
				method: "GET", // 设置请求方式
			}).then(res => {
				if (res.data.code == 1000) {
					//返回参数如果绑定微信返回token正常存储登录未绑定账号返回unionid绑定当然也可以以微信信息为主题自动生成账号
					setToken(res.data.data)
					
					setTimeout(() => {
						getInfodata().then(res => {
							if (res.code == 1000) {
								const data = JSON.stringify(res.data)
								setInfo(data)
								window.location.reload()
							}
						})
					}, 200)
				}
				if (res.data.code == 1002) {
					Notification.error({
						title: '提示',
						content: res.data.msg
					})
					setTimeout(() => {
						// process.env.VUE_APP_BASE_API
						window.location.href = `${'https://www.terapart.cn'}/login`
					}, 2000)
				}
				if (res.data.code == 1003) {
					const objdata = {
						id: res.data.data,
						type: 'wx'
					}
					setLoginform(JSON.stringify(objdata))
					store.dispatch('changloginform', true)
				}
			}).catch(() => {
				//返回失败因为二维码已经被使用过所以需要刷新重新获取
				window.location.href = `${'https://www.terapart.cn'}/login`
			});
		}
	}
	// access_token QQ登录
	if ((result.access_token && !result.userId) && !getToken()) {
		axios({
			url: `${process.env.VUE_APP_BASE_API}/atp/qq/callback?accessToken=${result.access_token}`, // 设置 URL
			method: "GET", // 设置请求方式
		}).then(res => {
			if (res.data.code == 1000) {
				setToken(res.data.data)
				setTimeout(() => {
					window.location.href = `${process.env.VUE_APP_BASE_API}/AccountManagement?index=3&userinfo=true`
				}, 100)
			}
			if (res.data.code == 1002) {
				Notification.error({
					title: '提示',
					content: res.data.msg
				})
				setTimeout(() => {
					// process.env.VUE_APP_BASE_API
					window.location.href = `${'https://www.terapart.cn'}/login`
				}, 2000)
			}
			if (res.data.code == 1003) {
				const objdata = {
					id: res.data.data,
					type: 'qq'
				}
				setLoginform(JSON.stringify(objdata))
				store.dispatch('changloginform', true)
			}
		}).catch(() => {
			//返回失败因为二维码已经被使用过所以需要刷新重新获取
			window.location.href = `${'https://www.terapart.cn'}/login`
		});
	}
	// QQ绑定授权
	// console.log(result)
	if (result.access_token && result.userId) {
		axios({
			url: `${process.env.VUE_APP_BASE_API}/atp/qq/bindQqCallback?accessToken=${result.access_token}&userId=${result.userId}`, // 设置 URL
			method: "GET", // 设置请求方式
		}).then(res => {
			if (res.data.code == 1000) {
				Message.success('QQ绑定成功~');
				window.location.href = `${process.env.VUE_APP_BASE_API}/AccountManagement?index=3&userinfo=true`
			} else {
				Notification.error({
					title: '提示',
					content: res.data.msg
				})
			}
		}).catch((error) => {
			// Notification.error({
			// 	title: '提示',
			// 	content: error.msg
			// })
		});
	}


	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()

	// else if (!getToken() && to.path !== '/Login') {
	// 	next('/Login');
	// }

	// if (!gettoken() && to.path !== '/login') {
	// 	next('/login');
	// }
});

createApp(App).use(store).use(router).use(ArcoVue).use(ArcoVueIcon).use(VueClipboard).use(animated).mount('#app')
