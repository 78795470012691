import Cookies from 'js-cookie'

const TokenKey = 'Token'
const UserInfo = 'Info'
const LoginStatus = 'LoginStatus'
const UserLoginInfo = 'UserLoginInfo'
const Loginform = 'Loginform'
// const WxMpQrUrl = 'wxMpQrUrl'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getInfo() {
  return Cookies.get(UserInfo)
}


export function getloginstatus() {
  return Cookies.get(LoginStatus)
}

export function getUserLoginInfo() {
  return Cookies.get(UserLoginInfo)
}

export function getLoginform() {
  return Cookies.get(Loginform)
}

// export function getwxMpQrUrl(wxMpQrUrl) {
//   return Cookies.set(WxMpQrUrl, wxMpQrUrl)
// }





export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setInfo(info) {
  return Cookies.set(UserInfo, info)
}

export function setloginstatus(status) {
  return Cookies.set(LoginStatus, status)
}

export function setUserLoginInfo(Loginform) {
  return Cookies.set(UserLoginInfo, Loginform)
}

export function setLoginform(data) {
  return Cookies.set(Loginform, data)
}

// export function setwxMpQrUrl(wxMpQrUrl) {
//   return Cookies.set(WxMpQrUrl, wxMpQrUrl)
// }


export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeInfo() {
  return Cookies.remove(UserInfo)
}

export function removeLoginStatus() {
  return Cookies.remove(LoginStatus)
}

export function removeUserLoginInfo() {
  return Cookies.remove(UserLoginInfo)
}

export function removeLoginform() {
  return Cookies.remove(Loginform)
}

// export function removeWxMpQrUrl() {
//   return Cookies.remove(WxMpQrUrl)
// }

