import { createStore } from 'vuex'

export default createStore({
  state: {
    tabindex: 0,
    tabheight:0,
    footerheight:0,
    tabvalue:0,
    contentheight:0,
    loginformbox:false,
    loginformid:null,
    ShoppingCartnum:0,
    Purchaserequestvalue:{},
    wxMpQrUrl:'https://www.terapart.cn/file/static/codeimg.jpg',
    isMouser:null,
    UserID:null,
    MSGreadCount:null
  },
  getters: {
  },
  mutations: {
      // tab高度
    changtabheight(state, tabheight) {
			state.tabheight = tabheight
      sessionStorage.setItem('tabheight',tabheight)
		},
      // tab下标
      changtabindex(state, tabindex) {
        state.tabindex = tabindex
      },
    // 底部高度
    changfooterheight(state, footerheight) {
			state.footerheight = footerheight
		},
    // tab value
    changtabvalue(state, tabvalue) {
			state.tabvalue = tabvalue
		},
     // 整体内容高度
     changcontentheight(state, contentheight) {
			state.contentheight = contentheight
		},
     //登录补充手机号弹框
     changloginformbox(state, loginformbox) {
			state.loginformbox = loginformbox
		},
    getloginformid(state, loginformid) {
			state.loginformid = loginformid
		},
    // 改变购物车
    changnum(state, num) {
			state.ShoppingCartnum = num
		},
    // 改变订购对应料单页面位置
    changPurchaserequestvalue(state, Newvalue) {
			state.Purchaserequestvalue = Newvalue
		},
    // 获取订购公众号二维码
    changwxMpQrUrl(state, NewWxMpQrUrl) {
			state.wxMpQrUrl = NewWxMpQrUrl
		},
    changisMouser(state, NewisMouser) {
			state.isMouser = NewisMouser
		},
    changUserID(state, NewUserID) {
			state.UserID = NewUserID
		},
    changMSGreadCount(state, NewCount) {
			state.MSGreadCount = NewCount
		},
  },
  actions: {
    changetabh(tab, addValue) {
			tab.commit('changtabheight', addValue);
		},
     changtabx(tabindex, addValue) {
      tabindex.commit('changtabindex', addValue);
    },
    changefooter(footer, addValue) {
			footer.commit('changfooterheight', addValue);
		},
    changtab(value, addValue) {
			value.commit('changtabvalue', addValue);
		},
    changcontent(value, addValue) {
			value.commit('changcontentheight', addValue);
		},
     changloginform(value, addValue) {
      value.commit('changloginformbox', addValue);
		},
    changloginformid(value, addValue) {
      value.commit('getloginformid', addValue);
		},
    changShoppingCartnum(value, addValue) {
      value.commit('changnum', addValue);
		},
    AddPurchaserequestvalue(value, addValue) {
      value.commit('changPurchaserequestvalue', addValue);
		},
    AddwxMpQrUrl(value, addValue) {
      value.commit('changwxMpQrUrl', addValue);
		},
    AddisMouser(value, addValue) {
      value.commit('changisMouser', addValue);
		},
    AddUserID(value, addValue) {
      value.commit('changUserID', addValue);
		},
    AddMSGreadCount(value, addValue) {
      value.commit('changMSGreadCount', addValue);
		},
  },
  modules: {
  }
})
