import axios from 'axios';
// import { ElMessage,ElNotification } from 'element-plus'
import router from '../router/index.js';
import { getToken, removeToken, removeInfo } from '@/utils/auth'
import { Notification } from '@arco-design/web-vue';
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 15000 // 请求超时时间
})
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

// 限制报错只弹出一次
var errorstatus = true
service.interceptors.request.use(function (config) {
	config.headers['Authorization'] = getToken()
	return config;
})

// 响应拦截  401 token过期处理
service.interceptors.response.use(
	function (response) {
		if (response.status == 200) {
			if (response.data.code == 1000) {
				return response.data;
			} else if (response.data.code == 0) {
				return response.data;
			} else if (response.data.code == 1002) {

				if (errorstatus  && response.data.msg) {
					Notification.error({
						title: '提示',
						content: response.data.msg
					})
					errorstatus = false
					setTimeout(() => {
						errorstatus = true
					}, 4000)
				}

				removeToken()
				// window.sessionStorage.removeItem('userorderinfo')
				// window.sessionStorage.removeItem('rfqinfo')
				removeInfo()
				// localStorage.clear()
				setTimeout(() => {
					return router.push('/login');
				}, 2000)
			} else {
				if (errorstatus && response.data.msg) {
					Notification.error({
						title: '提示',
						content: response.data.msg
					})
					errorstatus = false
					setTimeout(() => {
						errorstatus = true
					}, 4000)

				}

				// ElMessage.error(response.data.msg)
			}
		}

		return response.data
	},
	//接口错误状态处理，也就是说无响应时的处理
	function (error) {
		if (errorstatus) {
			Notification.error({
				title: '提示',
				content: '网络服务，连接失败！请稍后重试~'
			})
			errorstatus = false
			setTimeout(() => {
				errorstatus = true
			}, 4000)
		}
		// loadingInstance.close();
		return Promise.reject(error);
	}

);

export default service;
