import { createRouter, createWebHistory } from 'vue-router'



const routes = [
  {
		path: '/',
		name: 'Middle',
		component: () => import('../views/Middle.vue'),
		redirect: {
			name: "home"
		}, //输入路由center会重定向到a页面
		children: [{
			path: "/",
			name: "HomeView",
			component: () => import('@/views/HomeView.vue'),
			meta: {
				title: "环亚元电中文官网"
			}
		},
		{
			path: "Productlist",
			name: "Productlist",
			component: () => import('@/views/Productlist.vue'),
			meta: {
				title: "产品列表"
			}
		},{
			path: "myrfqs",
			name: "myrfqs",
			component: () => import('@/views/myrfqs.vue'),
			meta: {
				title: "BOM配单"
			}
		},{
			path: "chnatpstock",
			name: "chnatpstock",
			component: () => import('@/views/chnatpstock.vue'),
			meta: {
				title: "库存管理"
			}
		}
		// ,{
		// 	path: "chnatpsupplier",
		// 	name: "chnatpsupplier",
		// 	component: () => import('@/views/chnatpsupplier.vue'),
		// 	meta: {
		// 		title: "供应商申请"
		// 	}
		// }
		,{
			path: "linecard",
			name: "atplinecard",
			component: () => import('@/views/atplinecard.vue'),
			meta: {
				title: "品牌分类"
			}
		},
		{
			path: "Login",
			name: "Login",
			component: () => import('@/views/Login.vue'),
			meta: {
				title: "ATP登录页"
			}
		},
		{
			path: "Register",
			name: "Register",
			component: () => import('@/views/Register.vue'),
			meta: {
				title: "ATP注册页"
			}
		},
		{
			path: "Functional",
			name: "Functional",
			component: () => import('@/views/Functional.vue'),
			meta: {
				title: "功能分类"
			}
		},{
			path: "ShoppingCart",
			name: "ShoppingCart",
			component: () => import('@/views/ShoppingCart.vue'),
			meta: {
				title: "购物车"
			}
		}
		// ,{
		// 	path: "Aboutus",
		// 	name: "Aboutus",
		// 	component: () => import('@/views/Aboutus.vue'),
		// 	meta: {
		// 		title: "关于我们"
		// 	}
		// }
		,{
			path: "CompanyProfile",
			name: "CompanyProfile",
			component: () => import('@/views/CompanyProfile.vue'),
			meta: {
				title: "公司介绍"
			}
		},{
			path: "JoinATPPage",
			name: "JoinATPPage",
			component: () => import('@/views/JoinATPPage/index.vue'),
			meta: {
				title: "加入我们"
			}
		},{
			path: "ContactUs",
			name: "ContactUs",
			component: () => import('@/views/ContactUs.vue'),
			meta: {
				title: "联系我们"
			}
		},{
			path: "SupportHelp",
			name: "SupportHelp",
			component: () => import('@/views/SupportHelp.vue'),
			meta: {
				title: "支持与帮助"
			}
		},{
			path: "Information",
			name: "Information",
			component: () => import('@/views/ATPInformation.vue'),
			meta: {
				title: "ATP资讯"
			}
		},{
			path: "NewATPInformationDetali",
			name: "NewATPInformationDetali",
			component: () => import('@/views/NewATPInformationDetali.vue'),
			meta: {
				title: "ATP资讯详情"
			}
		}
		,{
			path: "InformationDetali",
			name: "InformationDetali",
			component: () => import('@/views/ATPInformationDetali.vue'),
			meta: {
				title: "ATP资讯详情"
			}
		},
		{
			path: "RecruitmentDetails",
			name: "RecruitmentDetails",
			component: () => import('@/views/RecruitmentDetails.vue'),
			meta: {
				title: "招聘详情"
			}
		},{
			path: "AccountManagement",
			name: "AccountManagement",
			component: () => import('@/views/AccountManagement/index.vue'),
			meta: {
				title: "账号管理"
			}
		},
		{
			path: "OrderCenter",
			name: "OrderCenter",
			component: () => import('@/views/OrderCenter/index.vue'),
			meta: {
				title: "订单中心"
			}
		},
		{
			path: "stock",
			name: "ATPstock",
			component: () => import('@/views/ATPstock.vue'),
			meta: {
				title: "产品详情"
			}
		},{
			path: "text",
			name: "text",
			component: () => import('@/views/text.vue'),
			meta: {
				title: "text"
			}
		},{
			path: "demo",
			name: "demo",
			component: () => import('@/views/demo.vue'),
			meta: {
				title: "demo"
			}
		},{
			path: "Qcservices",
			name: "Qcservices",
			component: () => import('@/views/Qcservices.vue'),
			meta: {
				title: "质检服务"
			}
		},
		{
			path: "PurchasingServices",
			name: "PurchasingServices",
			component: () => import('@/views/ATPpurchaserequest/index.vue'),
			meta: {
				title: "求购信息"
			}
		},
		{
			path: "PurchasingServicesAdd",
			name: "PurchasingServicesAdd",
			component: () => import('@/views/ATPpurchaserequest/ATPpurchaserequesadd.vue'),
			meta: {
				title: "发布求购",
				// keepAlive: true,
			},
		},
		{
			path: "/PurchasingServicesList",
			name: "PurchasingServicesList",
			component: () => import('@/views/ATPpurchaserequestList.vue'),
			meta: {
				title: "求购信息列表"
			}
		},
		{
			path: "/Moresupply",
			name: "Moresupply",
			component: () => import('@/views/Moresupply/index.vue'),
			meta: {
				title: "供应列表"
			}
		},
		{
			path: "PurchasingMGTCenter",
			name: "PurchasingMGTCenter",
			component: () => import('@/views/PurchasingMGTCenter/index.vue'),
			meta: {
				title: "求购管理中心"
			}
		},
		{
			path: "MessageList",
			name: "MessageList",
			component: () => import('@/views/MessageList.vue'),
			meta: {
				title: "系统通知"
			}
		},
		{
			path: '/:catchAll(.*)',
			name: 'demo',
			component: () => import('@/views/demo.vue')
		}
		// {
		// 	path: '*',
		// 	redirect: 'demo',
		// 	hidden: true
		// }
		]
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
