import http from '@/utils/http.js';


//折扣码校验
export function verifyCode(data) {
  return http({
    url: '/atp/api/discountcode/verifyCode',
    method: 'post',
	data: data
  })
}



// 获取品牌分类列表
export function getmanufacturerlist(data) {
  return http({
    url: '/atp/manufacturer/list', 
    method: 'get',
    params: data
  })
}


// 获取邮箱验证码
export function sendMailCode(data) {
  return http({
	  url: '/atp/atpuser/sendMail',
	  method: 'post',
	  data: data,
  })
}

// 邮箱验证
export function getverifyCode(data) {
  return http({
	  url: '/atp/atpuser/verifyCode',
	  method: 'post',
	  data: data,
  })
}


// 注册
export function atpregister(data) {
  return http({
	  url: '/atp/atpuser/register',
	  method: 'post',
	  data: data,
  })
}


// 登录
export function atplogin(data) {
  return http({
	  url: '/atp/atpuser/login',
	  method: 'post',
	  data: data,
  })
}


// 获取验证码
export function atpsendCode(data) {
  return http({
	  url: '/atp/atpuser/sendCode',
	  method: 'post',
	  data: data,
  })
}


//购物车详情
export function getcartdetail(id) {
  return http({
    url: '/atp/api/cart/' + id,
    method: 'post'
  })
}




//修改订单
export function orderupdate(data) {
  return http({
    url: '/atp/api/order', 
    method: 'put',
    data: data
  })
}

// 获取询价明细
export function getwebsiteinquirydetail(id) {
  return http({
    url: '/atp/website/inquiry/' + id,
    method: 'get'
  })
}



// 取消订单
export function ordercancel(id) {
  return http({
    url: '/atp/api/order/cancel/' + id,
    method: 'delete'
  })
}




// 检测数据创建
export function testingadd(data) {
  return http({
    url: '/atp/testing/add', 
    method: 'post',
    data: data
  })
}


// 获取检测销售人员数据选择列表
export function getquerySales(data) {
  return http({
    url: '/atp/testing/config/querySales', 
    method: 'get',
    params: data
  })
}

// 获取产品列表
export function getproductsearchlist(data) {
  return http({
    url: '/atp/product/search', 
    method: 'post',
    data: data
  })
}

//供应商申请
export function settledadd(data) {
  return http({
    url: '/atp/mouser/settled/add', 
    method: 'post',
    data: data
  })
}


// 获取产品明细
export function getwebsiteproductdetail(data) {
  return http({
    url: '/atp/product/getById', 
    method: 'get',
    params: data
  })
}


//询价申请
export function inquiryadd(data) {
  return http({
    url: '/atp/website/inquiry/add', 
    method: 'post',
    data: data
  })
}

//加入购物车
export function addcart(data) {
  return http({
    url: '/atp/api/cart/add', 
    method: 'post',
    data: data
  })
}

// 获取购物车
export function getcartlist(data) {
  return http({
    url: '/atp/api/cart/list', 
    method: 'get',
    params: data
  })
}

// 获取热门搜索记录
export function getHotPart(data) {
  return http({
    url: '/atp/userSearchRecord/getHotPart', 
    method: 'get',
    params: data
  })
}

// 删除购物车
export function cartdelete(ids) {
  return http({
    url: '/atp/api/cart/' + ids,
    method: 'delete'
  })
}

//修改购物车数量
export function updatecart(data) {
  return http({
    url: '/atp/api/cart/update', 
    method: 'post',
    data: data
  })
}

//新增-修改收货地址
export function useraddressadd(data) {
  return http({
    url: '/atp/useraddress/add', 
    method: 'post',
    data: data
  })
}

// 查询地区
export function getareastree(data) {
  return http({
    url: '/atp/api/areas/tree', 
    method: 'get',
    params: data
  })
}


//新增-修改发票抬头信息
export function invoiceheadadd(data) {
  return http({
    url: '/atp/invoice/head/add', 
    method: 'post',
    data: data
  })
}

//获取用户信息
export function getInfodata(data) {
  return http({
    url: '/atp/atpuser/getInfo', 
    method: 'get',
    params: data
  })
}

//获取用户地址信息
export function getAddressInfo(data) {
  return http({
    url: '/atp/atpuser/getAddressInfo', 
    method: 'get',
    params: data
  })
}

//注销用户官网信息
export function logoutAccount(data) {
  return http({
    url: '/atp/atpuser/logoutAccount', 
    method: 'get',
    params: data
  })
}


//修改手机号
export function resetMobile(data) {
  return http({
    url: '/atp/atpuser/resetMobile', 
    method: 'post',
    data: data
  })
}


//修改邮箱
export function resetEmail(data) {
  return http({
    url: '/atp/atpuser/resetEmail', 
    method: 'post',
    data: data
  })
}


//修改邮箱
export function atpuserresetPwd(data) {
  return http({
    url: '/atp/atpuser/resetPwd', 
    method: 'post',
    data: data
  })
}

//订单列表
export function getorderlist(data) {
  return http({
    url: '/atp/api/order/list', 
    method: 'get',
    params: data
  })
}



//用户询价列表
export function getinquirylist(data) {
  return http({
    url: '/atp/website/inquiry/list', 
    method: 'get',
    params: data
  })
}

//用户库存列表
export function getstockFormlist(data) {
  return http({
    url: '/atp/website/stockForm/list', 
    method: 'get',
    params: data
  })
}


// 用户询价详情
export function getinquiryqueryById(data) {
  return http({
    url: '/atp/website/inquiry/queryById', 
    method: 'get',
    params: data
  })
}


//确认订单
export function orderaffirm(data) {
  return http({
    url: '/atp/api/order/affirm', 
    method: 'put',
    data: data
  })
}

// 用户反馈
export function atpuserback(data) {
  return http({
    url: '/atp/api/back', 
    method: 'post',
    data: data
  })
}


//修改计算商品金额
export function atpcalculateAmount(data) {
  return http({
    url: '/atp/api/cart/calculateAmount', 
    method: 'post',
    data: data
  })
}

// 查询职位列表
export function gettemplatelist(data) {
  return http({
    url: '/atp/resume/template/list', 
    method: 'get',
    params: data
  })
}


//求职申请
export function atpresumeadd(data) {
  return http({
    url: '/atp/resume/add', 
    method: 'post',
    data: data
  })
}

// 查询相似产品
export function getsearchSimilar(data) {
  return http({
    url: '/atp/product/searchSimilar', 
    method: 'get',
    params: data
  })
}


// 获取订单明细
export function getorderdetail(data) {
  return http({
    url: '/atp/api/order/getById',
    method: 'get',
    params: data
  })
}


//获取协议
export function getprotocolInfo(data) {
  return http({
    url: '/atp/protocol/getInfo', 
    method: 'get',
    params: data
  })
}


//上传订单凭证
export function atpuploadCert(data) {
  return http({
    url: '/atp/api/order/uploadCert', 
    method: 'post',
    data: data
  })
}

// 订单取消
export function atpordercancel(data) {
  return http({
    url: '/atp/api/order/cancel', 
    method: 'post',
    params: data
  })
}

// 订单收货
export function atporderconfirm(data) {
  return http({
    url: '/atp/api/order/confirm', 
    method: 'post',
    params: data
  })
}

// 修改用户信息
export function atpuserupdate(data) {
  return http({
    url: '/atp/atpuser/update', 
    method: 'post',
    data: data
  })
}

// 获取检测栏目
export function getqueryProject(data) {
  return http({
    url: '/atp/testing/config/queryProject', 
    method: 'get',
    params: data
  })
}

// 获取检测数据列表
export function getqueryConfig(data) {
  return http({
    url: '/atp/testing/config/queryConfig', 
    method: 'get',
    params: data
  })
}

// 获取检测数据选择列表
export function getqueryByName(data) {
  return http({
    url: '/atp/testing/config/queryByName', 
    method: 'get',
    params: data
  })
}


// 质检计算
export function calculate(data) {
  return http({
    url: '/atp/testing/config/calculate', 
    method: 'post',
    params: data
  })
}


// 获取供应商记录列表
export function getmousersettledgetInfo(data) {
  return http({
    url: '/atp/mouser/settled/getInfo', 
    method: 'get',
    params: data
  })
}

// 获取供应商状态
export function getmousersettledStatus(data) {
  return http({
    url: '/atp/mouser/settled/getStatus', 
    method: 'get',
    params: data
  })
}

// 微信登录传递code及其他参数
export function setwxcallback(data) {
  return http({
    url: '/atp/wx/callback', 
    method: 'get',
    params: data
  })
}
// wx登录提交补充手机号
export function wxbindMobile(data) {
  return http({
    url: '/atp/wx/bindMobile', 
    method: 'post',
    data: data
  })
}

// qq登录提交补充手机号
export function qqbindMobile(data) {
  return http({
    url: '/atp/qq/bindMobile', 
    method: 'post',
    data: data
  })
}

// 解绑wx、qq
export function atpuserunBind(data) {
  return http({
    url: '/atp/atpuser/unBind', 
    method: 'get',
    params: data
  })
}

// 功能分类
export function atcategorylist(data) {
  return http({
    url: '/atp/category/list', 
    method: 'get',
    params: data
  })
}


// 质检服务历史数据
export function gettestinglist(data) {
  return http({
    url: '/atp/testing/list', 
    method: 'get',
    params: data
  })
}


// 获取微信绑定二维码
export function getWxQrcode(data) {
  return http({
    url: '/atp/wx/getWxQrcode', 
    method: 'get',
    params: data
  })
}



// 获取资讯列表
export function getnewsletterlist(data) {
  return http({
    url: '/atp/api/newsletter/list', 
    method: 'get',
    params: data
  })
}

// 获取热门标签列表
export function getpopularTagList(data) {
  return http({
    url: '/atp/api/newsletter/popularTagList', 
    method: 'get',
    params: data
  })
}

// 获取资讯详情
export function getnewsletterById(data) {
  return http({
    url: '/atp/api/newsletter/getById', 
    method: 'get',
    params: data
  })
}

// 资讯点赞
export function toBeLike(data) {
  return http({
    url: '/atp/api/newsletter/toBeLike', 
    method: 'get',
    params: data
  })
}


// 资讯评论
export function newsletterComment(data) {
  return http({
    url: '/atp/api/newsletter/comment/toComment', 
    method: 'post',
    data: data
  })
}
// 资讯评论列表
export function getComment(data) {
  return http({
    url: '/atp/api/newsletter/comment/getComment', 
    method: 'get',
    params: data
  })
}

// 资讯评论点赞
export function CommentBeLike(data) {
  return http({
    url: '/atp/api/newsletter/comment/toBeLike', 
    method: 'get',
    params: data
  })
}


// 获取求购首页列表
export function getpurchaseHomeList(data) {
  return http({
    url: '/atp/api/seek/purchase/getHomeList', 
    method: 'get',
    params: data
  })
}

// 获取求购列表
export function getpurchaselist(data) {
  return http({
    url: '/atp/api/seek/purchase/list', 
    method: 'get',
    params: data
  })
}


// 创建求购/供应数据及格式转换
export function seekpurchasetransfer(data) {
  return http({
    url: '/atp/api/seek/purchase/transfer', 
    method: 'post',
    data: data
  })
}

// 创建求购/供应数据及格式转换预览
export function purchasetableTransfer(data) {
  return http({
    url: '/atp/api/seek/purchase/tableTransfer', 
    method: 'post',
    data: data
  })
}

// 创建求购/供应数据
export function purchasesaveOrUpdate(data) {
  return http({
    url: '/atp/api/seek/purchase/saveOrUpdate', 
    method: 'post',
    data: data
  })
}


// 求购报价
export function purchasetoQuotation(data) {
  return http({
    url: '/atp/api/seek/purchase/toQuotation', 
    method: 'post',
    data: data
  })
}

// 获取WX签名
export function getwechatshare(data) {
  return http({
    url: '/atp/api/newsletter/wechat/share', 
    method: 'get',
    params: data
  })
}

// 求购询价
export function purchasetoWant(data) {
  return http({
    url: '/atp/api/seek/purchase/toWant', 
    method: 'post',
    data: data
  })
}
// 获取求购询价/报价记录
export function getquotationPage(data) {
  return http({
    url: '/atp/atpuser/quotationPage', 
    method: 'get',
    params: data
  })
}

// 获取求购询价详情
export function getquotationDetail(data) {
  return http({
    url: '/atp/atpuser/quotationSelfDetail', 
    method: 'get',
    params: data
  })
}

// 获取报价记录详情
export function getquotationOtherDetail(data) {
  return http({
    url: '/atp/atpuser/quotationOtherDetail', 
    method: 'get',
    params: data
  })
}

// 获取我发布的求购/供应列表
export function getpurchasePage(data) {
  return http({
    url: '/atp/atpuser/purchasePage', 
    method: 'get',
    params: data
  })
}

// 删除求购发布
export function atpuserpurchaseDelete(data) {
  return http({
    url: '/atp/atpuser/purchaseDelete', 
    method: 'post',
    data: data
  })
}

// 修改求购/供应信息
export function atpuserpurchaseUpdate(data) {
  return http({
    url: '/atp/atpuser/purchaseUpdate', 
    method: 'post',
    data: data
  })
}

// 修改求购/供应信息
export function atpuserothersSeekPage(data) {
  return http({
    url: '/atp/atpuser/othersSeekPage', 
    method: 'get',
    params: data
  })
}

// 获取系统通知列表
export function atpnewslist(data) {
  return http({
    url: '/atp/api/news/list', 
    method: 'get',
    params: data
  })
}

// 操作系统通知列表
export function atpsetRead(data) {
  return http({
    url: '/atp/api/news/setRead', 
    method: 'get',
    params: data
  })
}


 
// 回复求购留言
export function purchaseNewsReply(data) {
  return http({
    url: '/atp/atpuser/purchaseNewsReply', 
    method: 'post',
    data: data
  })
}

